// extracted by mini-css-extract-plugin
export var colorImg = "servicesSection-module--colorImg--Zg+Rg";
export var container = "servicesSection-module--container--OeViB";
export var discolorImg = "servicesSection-module--discolorImg--n7Ltm";
export var serviceBtn = "servicesSection-module--serviceBtn--Vqdjm";
export var serviceBtnDisabled = "servicesSection-module--serviceBtnDisabled--4YpHI";
export var serviceBtnEffect = "servicesSection-module--serviceBtnEffect--eYqWI";
export var serviceBtnMain = "servicesSection-module--serviceBtnMain--fbeBD";
export var serviceBtnTextBG = "servicesSection-module--serviceBtnTextBG--6XAZd";
export var serviceBtnTitle = "servicesSection-module--serviceBtnTitle--QocvB";
export var serviceImg = "servicesSection-module--serviceImg--hniKL";
export var servicesGrid = "servicesSection-module--servicesGrid--0yyd8";
export var slidedown = "servicesSection-module--slidedown--vHVCV";
export var slideup = "servicesSection-module--slideup--2nYat";