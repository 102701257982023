import React from "react"
import { Link } from "gatsby"
import * as servicesSectionStyles from "./servicesSection.module.css"
import Image from "./imageComponent"

import ScrollAnimation from "react-animate-on-scroll"
import "animate.css"

export default function ServiceBtn(props) {
  return (
    <Link to={props.link} id={props.id}>
      <ScrollAnimation
        animateIn={props.animateIn ? props.animateIn : ""}
        initiallyVisible={props.animateIn ? false : true}
        animateOnce={true}
        duration={0.5}
      >
        <div
          className={
            props.link
              ? props.mainCategory
                ? servicesSectionStyles.serviceBtnMain
                : servicesSectionStyles.serviceBtn
              : servicesSectionStyles.serviceBtnDisabled
          }
        >
          <Image
            alt={props.title + " services button"}
            fileName={props.fileName}
            imgClass={servicesSectionStyles.serviceImg}
            animated={false}
            aria-hidden
          />
          <div className={servicesSectionStyles.serviceBtnEffect}></div>
          <div className={servicesSectionStyles.serviceBtnTextBG}>
            <p className={servicesSectionStyles.serviceBtnTitle}>
              {props.title}
              <br />
              <span style={{ fontSize: "13px", color: "hsl(0, 0%, 80%)" }}>
                {" "}
                {props.subtitle}
              </span>
            </p>
          </div>
        </div>
      </ScrollAnimation>
    </Link>
  )
}
